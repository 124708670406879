#welcome {
    background: linear-gradient(rgba(0, 0, 0, 0.9),
    rgba(217, 83, 79, 0.3)), center no-repeat url('../public/background.jpg');
    background-attachment: fixed;
    background-size: cover;
}

#products {
    background: linear-gradient(rgba(150, 150, 150, 0.1),
    rgba(150, 150, 150, 0.1));
}

#researches {
    background: linear-gradient(rgba(150, 150, 150, 0.1),
    rgba(150, 150, 150, 0.1))
}

#technology {
    background: linear-gradient(rgba(150, 150, 150, 0.1),
    rgba(150, 150, 150, 0.1))
}

.row > div {
    margin-bottom: 15px;
}

.coverweb {
    height: 100vh;
}

.fullwidth-image {
    width: 100vw;
    height: 70vh;
}

.banner-list {
    padding-left: 2%;
    padding-right: 2%;
}

.margin-botop {
    margin-top: 10vh;
    margin-bottom: 8vh;
    padding: 40;
}

.garis-rapi {
    margin-left: 20vw;
    margin-right: 20vw;
}

.garis-rapi-2 {
    margin-left: 30%;
    margin-right: 30%;
}

.gambar-round {
    width: 17em;
    height: 17em;
    image-resolution: 200dpi;
    border-radius: 100%;
}

.our-layout {
    display: 'block';
    width: 'auto';
    padding: 40;
}

.nav.nav-pills .nav-link.active {
    background-color: 	#df4759;
}

.nav-item {
    color: rgb(46, 38, 38);
    background-color: 	#fadade;
}

.fullimage {
    height: 100%;
    width: 100%;
}

#menu {
    position: fixed;
}

@media (max-width: 1200px) {
    .Col-tech {
        margin-bottom: 10px;
        width: 100%;
    }

  }

  @media (max-width: 910px) {
    .border-left {
        margin-top: 0px;
    }

    .Col-tech {
        margin-bottom: 5px;
        width: 50%;
        padding: 2px;
    }

    .Row-tech {
        padding:0px !important;
        margin:0px !important;
    }

    .Row-research {
        padding:10px !important;
        margin:0px !important;
        font-size:0.9em;
    }

    .Row-products {
        padding:0px !important;
        margin:0px !important;
        font-size: 0.9em;
    }

    #portofolio {
        padding:0px !important;
        margin:0px !important;
        margin-bottom:30px !important;
        
    }

    .gambar-round {
        width: 11em;
        height: 11em;
        image-resolution: 200dpi;
        border-radius: 100%;
    }

    .services {
        padding:0px !important;
        margin:0px !important;
    }

    .owner{
        padding:0px !important;
        margin:0px !important;
    }

    .margin-botop {
        font-size: 0.8em;
    }

    .welcome {
        font-size: 0.8em;
    }

    .about.p {
        font-size: 0.9em;
        margin: 5px;
    }

    .btn {
        width: 7em;
        font-size: 0.9em;
    }

    .welcome-btn{
        width: 15em;
        font-size: 0.9em;
    }

    .footer{
        font-size: 0.9em ; 
      }

    h3 {
        font-size: 1.7em !important; 
      }
    
  }

  @media (max-width: 450px) {
    .gambar-round {
        width: 6em;
        height: 6em;
        image-resolution: 200dpi;
        border-radius: 100%;
    }

    #portofolio {
        padding:15px !important;
        margin:0px !important;
        margin-bottom:30px !important;
        
    }
  }